// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
import {getMainParentRouteName} from "@/lib/lib";

const routes = (process.env.VUE_APP_PACKAGE !== 'prometheus.portal') ? [] : [
    {
        path: 'fuel_cards',
        name: consts.routerNames.fuel_cards.main,
        meta: {
            list: 'FuelCardsList',
            mainRouteName: consts.routerNames.fuel_cards.main,
            pageLayout: 'page-global',
            pageClass: 'fuel-cards-page',
            showMap: true,
            setRoute: {
                fuelFull: (route, router) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.fuel_cards.main) {
                        if(this.$isDevelopment) console.warn('fuelFull', route);
                        return false;
                    }

                    router.push({
                        name: consts.routerNames.fuel_cards.full,
                        params: {},
                        query: {...route.query}
                    })
                },
                driverId:(route, router, id) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.fuel_cards.main) {
                        if(this.$isDevelopment) console.warn('driverId', route);
                        return false;
                    }

                    router.push({
                        name: consts.routerNames.fuel_cards.id,
                        params: {
                            driverId: id,
                            unitId: id,
                        },
                        query: {...route.query}
                    })
                },
            }
        },
        component: () => import('@/views/prometheus/portal/FuelCardsPage.vue'),
        children: [
            {
                path: 'all',
                name: consts.routerNames.fuel_cards.full,
                meta: {
                    parentRouteName: consts.routerNames.fuel_cards.main,
                    mainRouteName: consts.routerNames.fuel_cards.main,
                    pageClass: 'fuel-cards-page fuel-cards-page__all',
                    showMap: true
                },
                component: () => import('@/components/fuel_cards/FuelCards_all')
            },
            {
                path: ':driverId/:unitId?',
                name: consts.routerNames.fuel_cards.id,
                meta: {
                    parentRouteName: consts.routerNames.fuel_cards.main,
                    mainRouteName: consts.routerNames.fuel_cards.main,
                    pageClass: 'fuel-cards-page fuel-cards-page__id',
                    showMap: true
                },
                props: {
                    default: (route) => ({driverId: route.params.driverId})
                },
                components: {
                    default: () => import('@/components/fuel_cards/FuelCards_id')
                }
            }
        ]
    },
];

export default routes;
// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
const routes = (process.env.VUE_APP_PACKAGE !== 'prometheus.portal') ? [] : [
    {
        path: '/driver-score-cards',
        name: consts.routerNames.driver_score_cards.main,
        meta: {
            list: 'FuelCardsList',
            mainRouteName: consts.routerNames.driver_score_cards.main,
            pageLayout: 'page-global',
            pageClass: 'driver-score-cards-page',
            showMap: true,
            setRoute: {
            }
        },
        component: () => import('@/views/prometheus/portal/DriverScoreCardsPage.vue'),
        children: [
        ]
    },
];

export default routes;